import React, { useState, useEffect } from "react";
import { Spin } from "antd";

import * as styles from "./withClientRouteLoading.module.scss";

function withClientRouteLoading(WrappedComponent) {
  return props => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // componentDidMount - router initialized
      setLoading(false);
    }, []);

    if (loading) {
      return (
        <div className={styles.spin}>
          <Spin size="large" />
        </div>
      );
    }

    return <WrappedComponent {...props} />;
  };
}

export default withClientRouteLoading;
