import React from "react";
import { Router } from "@reach/router";

import { BlogPreviewIndex, BlogPostContainer } from "../components";

import withLayout from "../hoc/withLayout/withLayout";
import withClientRouteLoading from "../hoc/withClientRouteLoading/withClientRouteLoading";
import { useUserContext } from "../contexts/User/User.context";
import { createClientSideLocaleBasePath } from "../utils/helpers";

const BlogPreview = () => {
  const {
    state: {
      user: {
        locale: { code },
      },
    },
  } = useUserContext();

  const basepath = createClientSideLocaleBasePath(code, "/blog-preview");

  return (
    <Router basepath={basepath}>
      <BlogPreviewIndex path="/" />
      <BlogPostContainer path="/:slug" />
    </Router>
  );
};

export default withLayout(withClientRouteLoading(BlogPreview));
